










































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AuditFlowScope,
  InternalMonthlyPlanDto,
  OrganizationUnitDto,
  ProjectDto,
} from "@/api/appService";
import ExportWord from "@/components/DownloadWord/index.vue";
import api from "@/api";
import moment from "moment";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";


@Component({
  name: "internalMonthlyPlanDetail",
  components: {
    ExportWord,
    AuditBlock,
  },
  filters: {
    formatPartnerOrg(orgIds: number[], orgList: OrganizationUnitDto[]) {
      const selectOrgList: string[] = [];
      orgList.map((item: any) => {
        if (orgIds.indexOf(item.id) > -1) {
          selectOrgList.push(item.displayName);
        }
      });
      return selectOrgList.join(",");
    },
  },
})
export default class internalMonthlyPlanDetail extends Vue {

  orgId?: number;
  project: ProjectDto = {};
  detail: InternalMonthlyPlanDto = {
    project: this.project,
  };

  partnerOrgList: OrganizationUnitDto[] = [];
  selector = "table-detail";
  filename = "项目月计划表";
  styles = "";

  created() {
    this.fetchOrgAll();

    if (this.$route.params.id) {
      this.orgId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }


  // 获取详情
  async fetchDetail() {
    await api.internalMonthlyPlan.get({ id: this.orgId }).then((res) => {
      this.detail = { ...res };
    });
  }


  // 获取机构
  async fetchOrgAll() {
    await api.organizationUnit
      .getAll({ maxResultCount: 2000 })
      .then((res: any) => {
        if (res.items) {
          this.partnerOrgList = res.items;
        }
      });
  }

  get auditApi(){
    return {canAudit: api.internalMonthlyPlan.canAudit,userAudit: api.internalMonthlyPlan.audit} as AuditApi
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
