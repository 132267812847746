















































































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import {
  InternalMonthlyPlanCreateOrUpdateDto,
  InternalMonthlyPlanDetailCreateOrUpdateDto,
  OrganizationUnitDto,
} from "@/api/appService";
import api from "@/api";
import { ElForm } from "element-ui/types/form";
import ProjectSelect from "@/components/ProjectSelect/index.vue";

@Component({
  name: "internalMonthlyPlanEdit",
  components: {
    ProjectSelect,
  },
})
export default class internalMonthlyPlanEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;
  monthlyPlayList: InternalMonthlyPlanDetailCreateOrUpdateDto[] = [
    {
      id: undefined,

      title: undefined,

      sequence: undefined,

      place: undefined,

      /**  */
      startDate: undefined,

      /**  */
      endDate: undefined,

      /**  */
      summary: undefined,

      /**  */
      partnerOrgId: undefined,

      /**  */
      needSupport: undefined,

      /** 摘要 */
      remark: undefined,
    },
  ];
  defaultData: InternalMonthlyPlanCreateOrUpdateDto = {
    date: undefined,
    auditFlowId: undefined,
    projectId: undefined,
    details: this.monthlyPlayList,
    id: 0,
  };
  projectType = "";
  projectList = [];
  // 合作方机构列表
  partnerOrgList: OrganizationUnitDto[] = [];
  // 选择后合作方机构id
  partnerOrgIds = [];
  form: InternalMonthlyPlanCreateOrUpdateDto = { ...this.defaultData };

  // 添加一行
  private addRow(r: number) {
    let obj = {
      id: undefined,

      title: undefined,

      sequence: undefined,

      place: undefined,

      /**  */
      startDate: undefined,

      /**  */
      endDate: undefined,

      /**  */
      summary: undefined,

      /**  */
      partnerOrgId: undefined,

      /**  */
      needSupport: undefined,

      /** 摘要 */
      remark: undefined,
    };
    this.form.details = [...this.form.details!, obj];
  }

  // 删除整行
  private deleteRow(index: number) {
    this.form.details!.splice(index, 1);
  }

  created() {
    this.fetchOrgAll();
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.internalMonthlyPlan.get({ id: this.dataId }).then((res) => {
      this.form = { ...res };
      this.form.projectId = res!.project!.id;
      if (this.form.details && this.form.details.length > 0) {
        this.form.details!.map((item: any, index: number) => {
          item.savedPartnerOrgIds = item.savedPartnerOrgIds.map(Number);
        });
      }
    });
  }

  // 获取机构
  async fetchOrgAll() {
    await api.organizationUnit
      .getAll({ maxResultCount: 2000 })
      .then((res: any) => {
        this.partnerOrgList = res.items;
      });
  }

  cancel() {
    this.$router.go(-1);
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.internalMonthlyPlan.update({
            body: { ...this.form },
          });
        } else {
          await api.internalMonthlyPlan.create({
            body: { ...this.form },
          });
        }
        this.$message.success("更新成功");
        this.$router.go(-1);
      } else {
        this.$message({
          type: "error",
          message: "请检查表单",
        });
      }
    });
  }

  roleRule = {
    date: [
      {
        required: true,
        message: "请选择月份",
        trigger: "blur",
      },
    ],
    projectId: [
      {
        required: true,
        message: "请选择项目",
        trigger: "blur",
      },
    ],
  };
  detailRule = {
    title: [
      {
        required: true,
        message: "请输入活动主题",
        trigger: "blur",
      },
    ],

    place: [
      {
        required: true,
        message: "请输入活动地点",
        trigger: "blur",
      },
    ],
    summary: [
      {
        required: true,
        message: "请输入内容概述",
        trigger: "blur",
      },
    ],
    startDate: [
      {
        required: true,
        message: "请选择开始日期",
        trigger: "blur",
      },
    ],
    endDate: [
      {
        required: true,
        message: "请选择结束日期",
        trigger: "blur",
      },
    ],
  };
}
